import React, { lazy, Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import DashboardLayout from '../layouts/dashboard'
const PublicRoute = lazy(async () => import('./PublicRoute'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const Login = lazy(() => import('../pages/Login/index'))
const AdminProfile = lazy(() => import('../pages/AdminProfile/index'))
const Dashboard = lazy(() => import('../pages/Dashboard/index'))
const Events = lazy(() => import('../pages/Events/index'))
const EventOperation = lazy(() => import('../pages/Events/EventOperation'))
const RegisteredUsersForEvent = lazy(() => import('../pages/Events/RegisteredUsers/index'))
const Faculty = lazy(() => import('../pages/Faculty/index'))
const FacultyOperation = lazy(() => import('../pages/Faculty/FacultyOperation/index'))
const Coordinator = lazy(() => import('../pages/Coordinator/index'))
const CoordinatorOperation = lazy(() => import('../pages/Coordinator/CoordinatorOperation/index'))
const Gallery = lazy(() => import('../pages/Gallery/index'))
const GalleryOperation = lazy(() => import('../pages/Gallery/GalleryOperations/index'))
const Newsletters = lazy(() => import('../pages/Newsletter/index'))
const NewsletterOperation = lazy(() => import('../pages/Newsletter/NewsletterOperation/index'))
const MemberStudent = lazy(() => import('../pages/Member-Student/index'))
const ViewMemberStudent = lazy(() => import('../pages/Member-Student/ViewMember/index'))
const Transactions = lazy(() => import('../pages/Transactions/index'))
const NotFound = lazy(() => import('../components/NotFound'))
const Loader = lazy(() => import('../components/Loader'))

export default function Router () {
  return useRoutes([
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/events" /> },
        { path: '/404', element: <Suspense fallback={<Loader />}><NotFound /></Suspense> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '/login', element: <PublicRoute element={Login} /> },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/admin-profile', element: <PrivateRoute element={AdminProfile} /> },
        { path: '/dashboard', element: <PrivateRoute element={Dashboard} /> }
      ]
    },
    {
      path: '/events',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Events} /> },
        { path: 'add-event', element: <PrivateRoute element={EventOperation} /> },
        { path: 'update-event/:iEventId', element: <PrivateRoute element={EventOperation} /> },
        { path: 'registered-users/:iEventId', element: <PrivateRoute element={RegisteredUsersForEvent} /> },
        { path: 'transactions/:iEventId', element: <PrivateRoute element={Transactions} /> }
      ]
    },
    {
      path: '/faculties',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Faculty} /> },
        { path: 'add-faculty', element: <PrivateRoute element={FacultyOperation} /> },
        { path: 'update-faculty/:iFacultyId', element: <PrivateRoute element={FacultyOperation} /> }
      ]
    },
    {
      path: '/coordinators',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Coordinator} /> },
        { path: 'add-coordinator', element: <PrivateRoute element={CoordinatorOperation} /> },
        { path: 'update-coordinator/:iCoordinatorId', element: <PrivateRoute element={CoordinatorOperation} /> }
      ]
    },
    {
      path: '/gallery',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Gallery} /> },
        { path: 'add-record', element: <PrivateRoute element={GalleryOperation} /> },
        { path: 'update-record/:iRecordId', element: <PrivateRoute element={GalleryOperation} /> }
      ]
    },
    {
      path: '/newsletters',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Newsletters} /> },
        { path: 'add-newsletter', element: <PrivateRoute element={NewsletterOperation} /> },
        { path: 'update-newsletter/:iNewsletterId', element: <PrivateRoute element={NewsletterOperation} /> }
      ]
    },
    {
      path: '/members',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={MemberStudent} /> },
        { path: 'view-member/:iMemberId', element: <PrivateRoute element={ViewMemberStudent} /> },
        { path: 'edit-member/:iMemberId', element: <PrivateRoute element={ViewMemberStudent} /> },
        { path: 'transactions/:iUserId', element: <PrivateRoute element={Transactions} /> }
      ]
    },
    {
      path: '/students',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={MemberStudent} /> },
        { path: 'view-student/:iStudentId', element: <PrivateRoute element={ViewMemberStudent} /> },
        { path: 'edit-student/:iStudentId', element: <PrivateRoute element={ViewMemberStudent} /> }
      ]
    },
    {
      path: '/transactions',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PrivateRoute element={Transactions} /> }
      ]
    },
    {
      path: '*',
      element: <Navigate replace to="/404" />
    }
  ])
}
