import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Link, Drawer, Typography, Button } from '@mui/material'
// mock
import account from '../../_mock/account'
// hooks
import useResponsive from '../../hooks/useResponsive'
// components
// import Logo from '../../components/Logo'
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
//
import navConfig from './NavConfig'
import Iconify from '../../components/Iconify'
import { useMutation } from '@tanstack/react-query'
import { Logout } from '../../api/auth'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import { useMyContext } from '../../context/context'
import AlertComponent from '../../components/Alert'
import FullPageLoader from '../../components/Loader'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}))

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  textAlign: 'center'
}))

// const LogoutStyle = styled('div')(({ theme }) => ({
//   // display: 'flex',
//   // flexDirection: 'column',
//   // justifyContent: 'center',
//   // alignItems: 'flex-end',
//   // minHeight: '100px',
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: theme.palette.grey[500_12]
// }))

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
}

export default function DashboardSidebar ({ isOpenSidebar, onCloseSidebar }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { dispatch } = useMyContext()
  const adminData = JSON.parse(localStorage.getItem('AdminData'))
  const isDesktop = useResponsive('up', 'lg')
  const [modalOpen, setModalOpen] = useState(false)
  const [alert, setAlert] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
  }, [pathname])

  const { mutate: logout, isPending } = useMutation({
    mutationFn: Logout,
    onSuccess: () => {
      localStorage.removeItem('Token')
      localStorage.removeItem('AdminData')
      dispatch({ type: 'ADMIN_TOKEN', payload: '' })
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message)
      setAlert(true)
      setSuccess(false)
    }
  })

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleLogout = () => {
    logout({ sEmail: adminData?.sEmail })
    setModalOpen(false)
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <FullPageLoader isLoading={isPending} />
      {alert && <AlertComponent alert={alert} message={message} setAlert={setAlert} success={success} />}
      <Box sx={{ my: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
            <Box sx={{ ml: 2 }} onClick={() => navigate('/admin-profile')}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {adminData?.sFullName || 'CA Sagar Parmar'}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{
        position: 'fixed',
        bottom: '10px',
        left: '145px'
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'flex-end',
        // height: '100vh'
        // borderRadius: Number(theme.shape.borderRadius) * 1.5,
        // backgroundColor: theme.palette.grey[500_12]
      }}>
          <Typography sx={{ flex: 1 }}></Typography>
        {/* <LogoutStyle> */}
          <Button
            // variant='outlined'
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)'

            }}
            onClick={() => setModalOpen(true)}
          >Logout<Iconify icon="material-symbols:logout" width={30} height={30} sx={{ paddingLeft: '5px' }}/></Button>
        {/* </LogoutStyle> */}
      </Box>
    </Scrollbar>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
      {modalOpen && <DeleteConfirmationModal
        handleClose={handleClose}
        handleLogout={handleLogout}
        modalOpen={modalOpen}
      />}
    </RootStyle>
  )
}
