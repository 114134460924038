import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Modal, Typography } from '@mui/material'

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

function DeleteConfirmationModal (props) {
  const { handleClose, handleDelete, handleLogout, modalOpen } = props

  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            {handleLogout ? 'Are you sure you want to log out?' : handleDelete ? 'Are you sure you want to delete this record?' : ''}
        </Typography>
        <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
          <Button autoFocus onClick={handleClose} variant='outlined'>No</Button>
          <Button onClick={handleDelete || handleLogout} variant='outlined' sx={{ marginLeft: '10px' }}>Yes</Button>
        </Box>
      </Box>
    </Modal>
  )
}

DeleteConfirmationModal.propTypes = {
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleLogout: PropTypes.func,
  modalOpen: PropTypes.bool
}

export default DeleteConfirmationModal
