import React from 'react'
import { CircularProgress, Backdrop } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const useStyles = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  zIndex: 111,
  opacity: 1
}))

const FullPageLoader = ({ isLoading }) => {
  return (
    <Backdrop sx={{ zIndex: 9999 }} open={isLoading}>
      <div className={useStyles} style={{ pointerEvents: isLoading ? 'auto' : 'none' }}>
        <CircularProgress color="inherit"/>
      </div>
    </Backdrop>
  )
}

FullPageLoader.propTypes = {
  isLoading: PropTypes.bool
}

export default FullPageLoader
