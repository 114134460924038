import axios from '../axios/instanceAxios'

export const SendOtp = async (loginData) => {
  const { sEmail } = loginData
  return await axios.post('/auth/send-otp/v1', { sEmail })
}

export const Login = async (loginData) => {
  const { sEmail, nOtp } = loginData
  return await axios.post('/auth/login/v1', {
    sEmail, nOtp
  })
}

export const Logout = async (logoutData) => {
  const { sEmail } = logoutData
  return await axios.post('/auth/logout/v1', { sEmail })
}
