import React from 'react'
// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    title: 'events',
    path: '/events',
    icon: getIcon('mdi:events')
  },
  {
    title: 'Faculties',
    path: '/faculties',
    icon: getIcon('mdi:teacher')
  },
  {
    title: 'Coordinators',
    path: '/coordinators',
    icon: getIcon('mdi:moderator')
  },
  {
    title: 'gallery',
    path: '/gallery',
    icon: getIcon('solar:gallery-linear')
  },
  {
    title: 'newsletters',
    path: '/newsletters',
    icon: getIcon('mdi:email-newsletter')
  },
  {
    title: 'Members',
    path: '/members',
    icon: getIcon('material-symbols:card-membership')
  },
  {
    title: 'Students',
    path: '/students',
    icon: getIcon('ph:student-bold')
  },
  {
    title: 'Transactions',
    path: '/transactions',
    icon: getIcon('icon-park-outline:transaction-order')
  }
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill')
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill')
  // }
]

export default navConfig
