import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AllRoutes from './routes/index'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart'
import { useQuery } from '@tanstack/react-query'
import { useMyContext } from './context/context'
import { GetUrl } from './api/url'

const App = () => {
  const { dispatch } = useMyContext()

  const { data: sMediaUrl } = useQuery({
    queryKey: ['GetUrl'],
    queryFn: GetUrl,
    select: (response) => response?.data?.data
  })

  useEffect(() => {
    window.addEventListener('unauthorized', function () {
      dispatch({ type: 'ADMIN_TOKEN', payload: '' })
    })
  }, [])

  useEffect(() => {
    if (sMediaUrl) dispatch({ type: 'MEDIA_URL', payload: sMediaUrl })
  }, [sMediaUrl])

  return (
  <Router>
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <AllRoutes />
    </ThemeProvider>
  </Router>
  )
}

export default App
