import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css'
import App from './App'
import { QueryClientProvider } from '@tanstack/react-query'
import { client } from './api/client'
import { MyProvider } from './context/context'
import 'react-datepicker/dist/react-datepicker.css'
// import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={client}>
    <MyProvider>
      <App />
    </MyProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
