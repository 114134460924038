export default (state = { }, action) => {
  switch (action.type) {
    case 'ADMIN_TOKEN':
      return {
        ...state,
        token: action.payload
      }
    case 'MEDIA_URL':
      return {
        ...state,
        sMediaUrl: action.payload
      }
    case 'ADMIN_DATA':
      return {
        ...state,
        adminData: JSON.parse(localStorage.getItem('AdminData'))
      }
    default:
      return state
  }
}
